import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Buffer } from "buffer";
import "./IndexPage.css";

function IndexPage() {
  const { state = { success: false } } = useLocation();
  const navigate = useNavigate();
  const [animated1, setAnimated1] = useState(false);
  const [animated2, setAnimated2] = useState(false);
  const [animated3, setAnimated3] = useState(false);
  const [animated4, setAnimated4] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const closeModal = () => {
    setShowModal(false);
  };
  const className1 = animated1 ? "animated1" : "";
  const className2 = animated2 ? "animated2" : "";
  const className3 = animated3 ? "animated3" : "";
  const className4 = animated4 ? "animated4" : "";

  useEffect(() => {
    setTimeout(() => {
      setAnimated1(true);
      setAnimated2(true);
      setAnimated3(true);
      setAnimated4(true);
    }, 2500);
    window.scrollTo(0, 0);
    setShowModal(true);
  }, []);


  function handleLogout(event) {
    event.preventDefault();
    localStorage.removeItem("state");
    navigate("/");
  }

  //carrusel

  const [index, setIndex] = useState(0);

  const carousels = [
    "img/resenas1.png",
    "img/resenas2.png",
    "img/resenas3.png",
  ];

  const onNext = () => {
    console.log(carousels.length);

    if (index < carousels.length - 1) {
      let i = index + 1;
      setIndex(i);
    } else {
      setIndex(0);
    }
  };

  const links = [
    "https://www.thefork.es/restaurante/sun-taka-r463365?cc=16768-fbd&gclid=CjwKCAiA_vKeBhAdEiwAFb_nrTBlWNrvdGftQDAF5uoHF-js_T4VmP6ZJQYQ_kIR0o2CPG53k0-0jxoC_wkQAvD_BwE&gclsrc=aw.ds",
    "https://www.tripadvisor.es/ShowUserReviews-g187497-d15648197-r851144187-Sun_Taka-Barcelona_Catalonia.html",
    "https://www.google.com/maps/reviews/@41.3974079,2.1657301,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURCemJlaWRnEAE!2m1!1s0x0:0x658332dfdd86e08b?hl=es",
  ];

  //final carrusel

  // const [image, setImage] = useState(null);
  // const handleChange = (event) => {
  //   console.log(event.target.files);
  //   setImage(event.target.files[0]);
  // };
  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const formData = new FormData();
  //   formData.append("image", image);
  //   axios
  //     .post(
  //       "https://suntaka-backend-production.up.railway.app/api/gallery",
  //       formData
  //     )
  //     .then(() => {
  //       window.location.reload();
  //       console.log("Image saved to database");
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // const [image2, setImage2] = useState(null);
  // const handleChange2 = (event) => {
  //   console.log(event.target.files);
  //   setImage2(event.target.files[0]);
  // };
  // const handleSubmit2 = (event) => {
  //   event.preventDefault();

  //   const formData = new FormData();
  //   formData.append("image2", image2);
  //   axios
  //     .post(
  //       "https://suntaka-backend-production.up.railway.app/api/images",
  //       formData
  //     )
  //     .then(() => {
  //       window.location.reload();
  //       console.log("Image saved to database");
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // const [image3, setImage3] = useState(null);
  // const handleChange3 = (event) => {
  //   console.log(event.target.files);
  //   setImage3(event.target.files[0]);
  // };
  // const handleSubmit3 = (event) => {
  //   event.preventDefault();

  //   const formData = new FormData();
  //   formData.append("image3", image3);
  //   axios
  //     .post(
  //       "https://suntaka-backend-production.up.railway.app/api/pics",
  //       formData
  //     )
  //     .then(() => {
  //       window.location.reload();
  //       console.log("Image saved to database");
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // const [gallery, setGallery] = useState([]);

  // useEffect(() => {
  //   fetch("https://suntaka-backend-production.up.railway.app/api/galleryImage")
  //     .then((x) => x.json())
  //     .then((y) => y.data)
  //     .then((allGallery) => setGallery(allGallery));
  // }, []);

  // const [images, setImagenes] = useState([]);

  // useEffect(() => {
  //   fetch("https://suntaka-backend-production.up.railway.app/api/imagesImage")
  //     .then((x) => x.json())
  //     .then((y) => y.data)
  //     .then((allImagen) => setImagenes(allImagen));
  // }, []);

  // const [pics, setPics] = useState([]);

  // useEffect(() => {
  //   fetch("https://suntaka-backend-production.up.railway.app/api/picsImage")
  //     .then((x) => x.json())
  //     .then((y) => y.data)
  //     .then((allPics) => setPics(allPics));
  // }, []);

  // const handleDelete = (id) => {
  //   const requestInit = {
  //     method: "DELETE",
  //   };
  //   fetch(
  //     "https://suntaka-backend-production.up.railway.app/api/gallerydelete/" + id,
  //     requestInit
  //   ).then((res) => {
  //     if (res.ok) {
  //       window.location.reload();
  //     } else {
  //       console.log("Error al eliminar");
  //     }
  //   });
  // };

  // const handleDelete2 = (id) => {
  //   const requestInit = {
  //     method: "DELETE",
  //   };
  //   fetch(
  //     "https://suntaka-backend-production.up.railway.app/api/imagesdelete/" + id,
  //     requestInit
  //   ).then((res) => {
  //     if (res.ok) {
  //       window.location.reload();
  //     } else {
  //       console.log("Error al eliminar");
  //     }
  //   });
  // };

  // const handleDelete3 = (id) => {
  //   const requestInit = {
  //     method: "DELETE",
  //   };
  //   fetch(
  //     "https://suntaka-backend-production.up.railway.app/api/picsdelete/" + id,
  //     requestInit
  //   ).then((res) => {
  //     if (res.ok) {
  //       window.location.reload();
  //     } else {
  //       console.log("Error al eliminar");
  //     }
  //   });
  // };

  return (
    <div>
      {showModal ? (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              X
            </span>
            
            <img
              className="popup"
              src="img/VACACIONES25.jpg"
              alt="pop up"
            />
          </div>
        </div>
      ) : null}
      <header>
        <div className="presentation"></div>

        {state === null || !state.success || state.success === false ? (
          <p></p>
        ) : (
          <ul className="admin">
            <li>
              <a className="labeladmin2" href="/" onClick={handleLogout}>
                Desconectarse
              </a>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </li>
          </ul>
        )}
        <div>
          <img className="logo2" src="img/logo.gif" alt="logo" />

          {className1 ? (
            <>
              <div className="btn-flex-reservas">
                <a href="#reservation" className={className3}>
                  RESERVAR
                </a>
              </div>
              <div className="btn-flex">
                <a href="#aboutus" className={className1}>
                  SOBRE NOSOTROS
                </a>

                <a href="#card" className={className2}>
                  CARTA
                </a>

                <a href="#contact" className={className4}>
                  CONTACTO
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="btn-delay"></div>
            </>
          )}
        </div>
      </header>
      <p id="aboutus" className="titles about-us">
        SUN TAKA
      </p>
      <div className="container-aboutus1">
        {/* <div className="about-us-left">
          <img className="suntaka" width="70%" src="img/gal6.jpg" alt="soplete" />
          <img className="suntaka2" width="70%" src="img/gal6.jpg" alt="soplete" />
        </div> */}
        <div className="about-us-left">
          <div className="image-suntaka-1">
            <img
              className="suntaka"
              width="70%"
              src="img/gal6.jpg"
              alt="soplete"
            />
          </div>
          <div className="image-suntaka-2">
            <img
              className="suntaka"
              width="70%"
              src="img/carrusel4.jpg"
              alt="soplete"
            />
          </div>
        </div>
        <div className="about-us-right">
          <p className="aboutus-text">
            Sun Taka es un concepto de Izakaya situado en Barcelona, un estilo
            de taberna japonesa. Su cálido y tradicional ambiente pero a su vez
            elegante, lo hacen especialmente acogedor. <br></br>
            <br></br>
            {/* En el Sun Taka se respira la esencia de los restaurantes clásicos */}
            Se respira la esencia de los restaurantes clásicos japoneses.
            {/* <br></br>
            <br></br> */}
            {/* La cocina nipona  */}
            &nbsp; Su cocina es muy diversa y variada y ofrece un viaje
            gastronomico
            {/* amplio abanico de esta cocina de excelencia */}a través de una
            carta muy amplia: pescado, marisco, sushi, carne, wagyu A5.{" "}
            <br></br>
            <br></br>
            Siempre apostando por un producto de calidad y de temporada,
            elaborado con máxima delicadeza y frescura, manteniendo las técnicas
            tradicionales japonesas.<br></br>
            <br></br>
            Destaca su carta de sakes originarios de Japón y una amplia carta de
            vinos nacionales e internacionales. que no pasarán desapercibidos.
            {/* No nos podemos olvidar de los postres, totalmente únicos creados por
            nuestro Chef, en los cuales mezcla sabores dispares, técnicas y
            texturas. Lo que se encuentra en Sun Taka es una experiencia
            gastronómica única en un entorno profesional y a su vez aporta a los
            clientes una calidez que solo Taka y su equipo pueden brindar. */}
          </p>
        </div>
      </div>

      <div className="barrido"></div>
      <p className="titles about-us2">EL CHEF</p>
      <div className="container-aboutus2">
        <div className="about-us-left2">
          <p className="aboutus-text">
            Mitsutaka Kawata, conocido como “Taka”, es un chef y viajero
            originario de Osaka (Japón). Cuando tenía 19 años en uno de sus
            viajes fuera de Japón, tomó la decisión de convertirse en cocinero
            para difundir su propio concepto de cocina japonesa. Taka se inspiró en su maestro Nakamura, donde se formó durante 8
            años en cocina tradicional japonesa en el restaurante Wayo Yuzen
            Nakamura en su Osaka natal.<br></br>
            <br></br>
            {/* Le caracteriza su gran nivel técnico, su pulcritud y destreza a la
            hora de trabajar con una inquietud por tratar siempre con el mejor
            producto.<br></br>
            <br></br> */}
            Cuando se estableció en España, tuvo la oportunidad de
trabajar en lugares prestigiosos como Baluarte, Can Fabes (Sant
            Celoni) y en Hotel Ferrero al lado de Chefs internacionales
            reconocidos. Además, montó la barra de sushi
            del Cercle d'Artistes, en Barcelona, donde fue el Chef por más de 5
            años.<br></br>
            <br></br>
            Con una visión muy clara en encontrar una expresión moderna y fresca
            de la gastronomía japonesa, abre una nueva etapa creando su propia
            Izakaya, un estilo de taberna japonesa en Barcelona y así nace el
            restaurante Sun Taka.<br></br>
            <br></br>
             Actualmente cuenta con el soporte
            excelentes cocineros japoneses que se destacan por su capacidad de
            crear “platos familiares” con un toque personalizado, creando una
            fusión de sabores que constituyen una obra única.<br></br>
            <br></br>
            La determinación de sus manos y los minuciosos cortes de cuchillo
            hacen que observar a Taka en la barra se convierta en un espectáculo
            gastronómico. <br></br>
            <br></br>
          </p>
        </div>
        <div className="about-us-right2">
          <div className="image-chef-1">
            <img width="80%" src="img/kawata-mitsutaka3.jpeg" alt="" />
          </div>
          <div className="image-chef-2">
            <img width="80%" src="img/elchef.jpeg" alt="" />
          </div>
          <div className="image-chef-3">
            <img width="80%" src="img/elchef2.jpeg" alt="" />
          </div>
        </div>
      </div>
      <p className="aboutus-text2"></p>
      <div className="aboutus-text2">
        <p>
          El chef Mitsutaka Kawata es uno de los embajadores, junto con otros
          chefs, del Cook & Chef Institute.
        </p>
        <br></br>
        <a
          className="embajadores-btn"
          target="_blank"
          rel="noreferrer"
          href="https://www.cookandchefinstitute.com/es/chef/kawata-mitsutaka"
        >
          Descúbrelo aquí
        </a>
      </div>
      <div className="barrido1"></div>
      <div className="container-gallery">
        <img className="image" src="img/1.jpg" alt="Imagen de galería" />
        <img className="image" src="img/2.jpeg" alt="Imagen de galería 2" />
        <img className="image" src="img/3.jpg" alt="Imagen de galería 3" />
        <img className="image" src="img/4.jpg" alt="Imagen de galería 4" />
        <img className="image" src="img/5.jpg" alt="Imagen de galería 5" />
        <img className="image" src="img/5.2.jpg" alt="Imagen de galería 5.1" />
      </div>
      <div className="container-gallery2">
        <img className="image" src="img/6.1.jpg" alt="Imagen de galería 6" />
        <img className="image" src="img/7.1.jpg" alt="Imagen de galería 7" />
        <img className="image" src="img/8.jpeg" alt="Imagen de galería 8" />
        <img className="image" src="img/9.JPG" alt="Imagen de galería 9" />
        <img className="image" src="img/10.JPG" alt="Imagen de galería 10" />
        <img
      
          className="image"
          src="img/10.1.jpg"
          alt="Imagen de galería 10.1"
        />
        <img
      
          className="image"
          src="img/10.2.PNG"
          alt="Imagen de galería 10.2"
        />
      </div>
      <div className="container-gallery2">
        <img className="image" src="img/11.1.jpg" alt="Imagen de galería 11" />
        <img className="image" src="img/12.jpg" alt="Imagen de galería 12" />
        <img className="image" src="img/13.JPG" alt="Imagen de galería 13" />
        <img className="image" src="img/14.JPG" alt="Imagen de galería 14" />
        <img className="image" src="img/15.JPG" alt="Imagen de galería 15" />
        <img className="image" src="img/16.jpg" alt="Imagen de galería 16" />
      </div>

      {/* <div className="container-gallery">
        {gallery &&
          gallery.map((gallery) => {
            return (
              <div key={gallery.id}>
                <div>
                  <img
                    className="image"
                    src={`data:image/jpeg;base64,${Buffer.from(
                      gallery.data,
                      "binary"
                    ).toString()}`}
                    alt={`Imagen del ordenador ${gallery.nombre}`}
                  />
                  {state === null ||
                  state.user.usuario === null ||
                  !state.user ? (
                    <p></p>
                  ) : state.user.usuario === "suntaka" ? (
                    <div>
                      <p
                        className="btn"
                        onClick={() => handleDelete(gallery.id)}
                      >
                        Eliminar
                      </p>

                      <br></br>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        {state === null || state.user.usuario === null || !state.user ? (
          <p></p>
        ) : state.user.usuario === "suntaka" ? (
          <form onSubmit={handleSubmit}>
            <br />
            <input
              className="labeladmin btn"
              type="file"
              onChange={handleChange}
            />
            <button className="btn" type="submit">
              Subir
            </button>
          </form>
        ) : null}
      </div>

      <div className="container-gallery2">
        {images &&
          images.map((images) => {
            return (
              <div key={images.id}>
                <div>
                  <img
                    className="image"
                    src={`data:image/jpeg;base64,${Buffer.from(
                      images.data,
                      "binary"
                    ).toString()}`}
                    alt={`Imagen del ordenador ${images.nombre}`}
                  />
                  {state === null ||
                  state.user.usuario === null ||
                  !state.user ? (
                    <p></p>
                  ) : state.user.usuario === "suntaka" ? (
                    <div>
                      <p
                        className="btn"
                        onClick={() => handleDelete2(images.id)}
                      >
                        Eliminar
                      </p>

                      <br></br>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        {state === null || state.user.usuario === null || !state.user ? (
          <p></p>
        ) : state.user.usuario === "suntaka" ? (
          <form onSubmit={handleSubmit2}>
            <br />
            <input
              className="labeladmin btn"
              type="file"
              onChange={handleChange2}
            />
            <button className="btn" type="submit">
              Subir
            </button>
          </form>
        ) : null}
      </div>

      <div className="container-gallery2">
        {pics &&
          pics.map((pic) => {
            return (
              <div key={pic.id}>
                <div>
                  <img
                    className="image"
                    src={`data:image/jpeg;base64,${Buffer.from(
                      pic.data,
                      "binary"
                    ).toString()}`}
                    alt={`Imagen del ordenador ${pic.nombre}`}
                  />
                  {state === null ||
                  state.user.usuario === null ||
                  !state.user ? (
                    <p></p>
                  ) : state.user.usuario === "suntaka" ? (
                    <div>
                      <p className="btn" onClick={() => handleDelete3(pic.id)}>
                        Eliminar
                      </p>

                      <br></br>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        {state === null || state.user.usuario === null || !state.user ? (
          <p></p>
        ) : state.user.usuario === "suntaka" ? (
          <form onSubmit={handleSubmit3}>
            <br />
            <input
              className="labeladmin btn"
              type="file"
              onChange={handleChange3}
            />
            <button className="btn" type="submit">
              Subir
            </button>
          </form>
        ) : null}
      </div> */}

      <p id="card" className="titles card">
        LA CARTA
      </p>
      <p className="frase">
        <i className="cita">
          “Creo fundamentalmente en respetar cada regalo que la naturaleza nos
          hace.”
        </i>
        <br></br>
        <strong className="citador">Mitsutaka Kawata </strong>
      </p>

      <div className="container-father">
        <div className="container-btn">
        <a
  className="btn3"
  href="/sugested"
  rel="noopener noreferrer"
>
            <p className="btn-text">SUGERENCIAS</p>
            <img
            
              className="carta-img"
              src="img/LOGO7.png"
              width="100%"
              alt="sugerencias"
            />
          </a>

          {state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "suntaka" ? (
            <p
              className="btn btn-edit"
              onClick={() => navigate("/sugestedEdit", { state })}
            >
              ✏️ Editar sugerencias
            </p>
          ) : null}

<a
  className="btn3"
  href="/card"
  rel="noopener noreferrer"
>
            <p className="btn-text">CARTA</p>
            <img
            
              className="carta-img"
              src="img/LOGO8.png"
              width="100%"
              alt="carta"
            />
          </a>
          {state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "suntaka" ? (
            <p
              className="btn btn-edit"
              onClick={() => navigate("/cardEdit", { state })}
            >
              ✏️ Editar carta
            </p>
          ) : null}

<a
  className="btn3"
  href="/winesake"
  rel="noopener noreferrer"
>
  <p className="btn-text">BODEGA</p>
  <img
    className="carta-img"
    src="img/LOGO5.png"
    width="100%"
    alt="vinos y sake"
  />
</a>
          {state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "suntaka" ? (
            <p
              className="btn btn-edit"
              onClick={() => navigate("/winesakeEdit", { state })}
            >
              ✏️ Editar Vinos & Sake
            </p>
          ) : null}

<a
  className="btn3"
  href="/desserts"
  rel="noopener noreferrer"
>
            <p className="btn-text">POSTRES</p>
            <img
            
              className="carta-img"
              src="img/LOGO6.png"
              width="100%"
              alt="postres"
            />
          </a>
          {state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "suntaka" ? (
            <p
              className="btn btn-edit"
              onClick={() => navigate("/dessertsEdit", { state })}
            >
              ✏️ Editar Postres
            </p>
          ) : null}
        </div>
      </div>
      <div className="barrido2"></div>
      <div className="reservation-container">
        <p id="reservation" className="titles">
          RESERVAS
        </p>
        <div className="reservation-text">
          <a href="tel:932502349">Reserva llamando al</a>
        </div>
        <br></br>
        <div className="reservation-text2">
          <a className="reservation-text-color" href="tel:932502349">
            932 50 23 49
          </a>
        </div>
        <div className="reservation-text3">
          <p>o reserva online</p>
        </div>
        <div className="reservas-container">
          <a
            className="reservaonline"
            href=" https://module.lafourchette.com/es_ES/module/463365-22033 "
            target="_blank"
            rel="noreferrer"
          >
            Reserva online
          </a>
        </div>
      </div>
      <div className="barrido3"></div>
      <p id="review" className="titles">
        LO QUE DICEN DE NOSOTROS
      </p>
      <div className="carousel">
        {carousels.map((item, i) =>
          i === index ? (
            <div className="carousel-item" key={i}>
              {links.map((link, j) =>
                j === index ? (
                  <div className="carousel-items" key={j}>
                    <a target="_blank" rel="noreferrer" href={link}>
                      <img src={item} alt={item} />
                    </a>
                  </div>
                ) : null
              )}
            </div>
          ) : null
        )}

        <p className="next" onClick={onNext}>
          Siguiente reseña
        </p>
        <a className="next2" href="https://g.page/r/CYvght3fMoNlEB0/review">
          Cuéntanos tu experiencia
        </a>
      </div>

      <img className="abanico" src="img/abanico2.png" alt="abanico" />
      <footer id="contact">
        <div className="footer-container">
          <div className="footer-section1">
            <h3>Contacto</h3>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.es/maps/place/SUN+TAKA/@41.3974079,2.1635414,17z/data=!3m1!4b1!4m5!3m4!1s0x12a4a3eb0bb6f8db:0x658332dfdd86e08b!8m2!3d41.3974079!4d2.1657301?hl=es"
            >
              Dirección: Carrer del Bruc, 146, 08037 Barcelona
            </a>
            <br></br>
            <br></br>
            <a href="tel:932502349">Teléfono: 932 50 23 49</a>
            <br></br>
            <br></br>
            <a href="mailto:suntakabarcelona@gmail.com">
              Email: suntakabarcelona@gmail.com
            </a>
          </div>
          <div className="footer-section2">
            <h3>Horarios</h3>
            <p>Miércoles a Domingo</p>
            <p>Mediodía: 13.00h-15.30h; Noche: 20.00h-23.00h </p>
            <p>Lunes y Martes cerrado </p>
          </div>
        </div>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/search?q=sun+taka+google&sxsrf=AJOqlzUg9qWW0yug8c3lZ83PS6YBnizzLg%3A1675213830008&ei=BrzZY-UWo9jFzw_19LagBw&ved=0ahUKEwjlsYrqkfP8AhUjbPEDHXW6DXQQ4dUDCA8&uact=5&oq=sun+taka+google&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgATIFCCEQoAE6BwgjELADECc6CggAEEcQ1gQQsAM6BwgAELADEEM6EgguEMcBEK8BEMgDELADEEMYAToSCC4QrwEQxwEQyAMQsAMQQxgBOgQIIxAnOgsILhCvARDHARCABDoFCAAQgAQ6CAgAEIAEEMsBOgYIABAWEB46CAgAEBYQHhAPOgIIJjoFCAAQogRKBAhBGABKBAhGGAFQowZYlRVg4hZoAnAAeACAAYQBiAHPB5IBAzAuOJgBAKABAcgBFMABAdoBBggBEAEYCA&sclient=gws-wiz-serp#lrd=0x12a4a3eb0bb6f8db:0x658332dfdd86e08b,1,,,,"
          >
            <img
              className="google"
              src="img/google.png"
              width="3%"
              alt="google"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/SunTakabarcelona/"
          >
            <img
              className="facebook"
              src="img/facebook3.png"
              width="3%"
              alt="facebook"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/suntaka.bcn/?igshid=YmMyMTA2M2Y%3D"
          >
            <img
              className="instagram"
              src="img/instagram.svg"
              width="3%"
              alt="instagram"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.tripadvisor.es/Restaurant_Review-g187497-d15648197-Reviews-Sun_Taka-Barcelona_Catalonia.html"
          >
            <img
              className="tripadvisor"
              src="img/tripadvisor.png"
              width="3%"
              alt="tripadvisor"
            />
          </a>
        </div>
        <div className="copyright">
          <p>Copyright © 2023 Sun Taka. All Rights Reserved.</p>
          <p>Sito web creado por The PopCorn.</p>
        </div>
      </footer>
    </div>
  );
}

export default IndexPage;
